import { createRouter, createWebHashHistory } from "vue-router";
import login from "@/views/login/login.vue";
import { manejadorRutas } from "@/utils/manejador-rutas";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      name: "login",
      component: login,
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/home/home.vue"),
      children: [
        {
          path: "/tablero-general",
          name: "tablero general",
          component: () =>
            import("@/views/tablero-principal/tablero-principal.vue"),
        },
        {
          path: "/centro-de-costos",
          name: "centro de costos",
          component: () => import("@/views/sede/sede.vue"),
        },
        {
          path: "/centro-de-costos/create",
          name: "crear centro de costos",
          component: () => import("@/views/sede/formulario/formulario.vue"),
        },
        {
          path: "/centro-de-costos/update/:id",
          name: "actualizar centro de costos",
          component: () => import("@/views/sede/formulario/formulario.vue"),
        },
        {
          path: "/subcentro-costos",
          name: "subcentro de costos",
          component: () => import("@/views/subcentro/subcentro.vue"),
        },
        {
          path: "/subcentro-costos/create",
          name: "crear subcentro de costos",
          component: () =>
            import("@/views/subcentro/formulario/formulario.vue"),
        },
        {
          path: "/subcentro-costos/update/:id",
          name: "actualizar subcentro de costos",
          component: () =>
            import("@/views/subcentro/formulario/formulario.vue"),
        },
        {
          path: "/terceros",
          name: "tercero",
          component: () => import("@/views/tercero/tercero.vue"),
        },
        {
          path: "/terceros/create",
          name: "crear tercero",
          component: () => import("@/views/tercero/formulario/formulario.vue"),
        },
        {
          path: "/terceros/update/:id",
          name: "actualizar tercero",
          component: () => import("@/views/tercero/formulario/formulario.vue"),
        },
        {
          path: "/vehiculos",
          name: "vehículos",
          component: () => import("@/views/vehiculos/vehiculos.vue"),
        },
        {
          path: "/vehiculos/create",
          name: "crear vehículos",
          component: () =>
            import("@/views/vehiculos/formulario/formulario.vue"),
        },
        {
          path: "/vehiculos/update/:id",
          name: "actualizar vehículos",
          component: () =>
            import("@/views/vehiculos/formulario/formulario.vue"),
        },
        {
          path: "/orden-cargue",
          name: "orden cargue",
          component: () => import("@/views/orden-carga/orden-carga.vue"),
        },
        {
          path: "/orden-cargue/create",
          name: "crear orden cargue",
          component: () =>
            import("@/views/orden-carga/formulario/formulario.vue"),
        },
        {
          path: "/orden-cargue/update/:id",
          name: "actualizar orden cargue",
          component: () =>
            import("@/views/orden-carga/formulario/formulario.vue"),
        },
        {
          path: "/remesas",
          name: "remesas",
          component: () => import("@/views/remesas/remesas.vue"),
        },
        {
          path: "/remesas/summary",
          name: "resumen De remesas",
          component: () => import("@/views/remesas/resumen/resumen.vue"),
        },
        {
          path: "/remesas/create",
          name: "crear remesas",
          component: () => import("@/views/remesas/formulario/formulario.vue"),
        },
        {
          path: "/remesas/update/:id",
          name: "actualizar remesas",
          component: () => import("@/views/remesas/formulario/formulario.vue"),
        },
        {
          path: "/remesas/servicios/create",
          name: "crear servicios a otros proveedores",
          component: () => import("@/views/remesas/servicios/servicios.vue"),
        },
        {
          path: "/remesas/servicios/update/:id",
          name: "actualizar servicios a otros proveedores",
          component: () => import("@/views/remesas/servicios/servicios.vue"),
        },
        {
          path: "/manifiestos",
          name: "manifiestos",
          component: () => import("@/views/manifiesto/manifiesto.vue"),
        },
        {
          path: "/manifiestos/create",
          name: "crear manifiestos",
          component: () =>
            import("@/views/manifiesto/formulario/formulario.vue"),
        },
        {
          path: "/manifiestos/update/:id",
          name: "actualizar manifiestos",
          component: () =>
            import("@/views/manifiesto/formulario/formulario.vue"),
        },
        {
          path: "/mensajes",
          name: "Mensajes",
          component: () => import("@/views/mensajes/mensajes.vue"),
        },
        {
          path: "/mensajes/update/:id",
          name: "actualizar Mensajes",
          component: () => import("@/views/mensajes/formulario/formulario.vue"),
        },
        {
          path: "/documento",
          name: "documentos",
          component: () => import("@/views/documento/documento.vue"),
        },
        {
          path: "/documento/create",
          name: "crear documento",
          component: () =>
            import("@/views/documento/formulario/formulario.vue"),
        },
        {
          path: "/documento/update/:id",
          name: "actualizar documento",
          component: () =>
            import("@/views/documento/formulario/formulario.vue"),
        },
        {
          path: "/anticipo",
          name: "anticipos",
          component: () => import("@/views/anticipo/anticipos.vue"),
        },
        {
          path: "/traslado-anticipo",
          name: "traslado anticipo",
          component: () =>
            import("@/views/traslado-anticipo/traslado-anticipo.vue"),
        },
        {
          path: "/anticipo/create",
          name: "crear anticipo",
          component: () => import("@/views/anticipo/formulario/formulario.vue"),
        },
        {
          path: "/anticipo/update/:id",
          name: "actualizar anticipo",
          component: () => import("@/views/anticipo/formulario/formulario.vue"),
        },
        {
          path: "/categorias-retencion",
          name: "categorías de retención",
          component: () =>
            import("@/views/categoria-retencion/categoria-retencion.vue"),
        },
        {
          path: "/categorias-retencion/create",
          name: "crear categorías",
          component: () =>
            import("@/views/categoria-retencion/formulario/formulario.vue"),
        },
        {
          path: "/categorias-retencion/update/:id",
          name: "actualizar categorías",
          component: () =>
            import("@/views/categoria-retencion/formulario/formulario.vue"),
        },
        {
          path: "/productos",
          name: "productos",
          component: () => import("@/views/producto/producto.vue"),
        },
        {
          path: "/productos/create",
          name: "crear producto",
          component: () => import("@/views/producto/formulario/formulario.vue"),
        },
        {
          path: "/productos/update/:id",
          name: "actualizar producto",
          component: () => import("@/views/producto/formulario/formulario.vue"),
        },
        {
          path: "/producto-homologacion",
          name: "producto homologación",
          component: () =>
            import("@/views/producto-homologacion/producto-homologacion.vue"),
        },
        {
          path: "/producto-homologacion/create",
          name: "crear producto homologación",
          component: () =>
            import("@/views/producto-homologacion/formulario/formulario.vue"),
        },
        {
          path: "/producto-homologacion/update/:id",
          name: "actualizar producto homologación",
          component: () =>
            import("@/views/producto-homologacion/formulario/formulario.vue"),
        },
        {
          path: "/bancos",
          name: "banco",
          component: () => import("@/views/banco/banco.vue"),
        },
        {
          path: "/bancos/create",
          name: "crear banco",
          component: () => import("@/views/banco/formulario/formulario.vue"),
        },
        {
          path: "/bancos/update/:id",
          name: "actualizar banco",
          component: () => import("@/views/banco/formulario/formulario.vue"),
        },
        {
          path: "/retenciones-automaticas",
          name: "retenciones automáticas",
          component: () => import("@/views/retenciones/retenciones.vue"),
        },
        {
          path: "/retenciones-automaticas/create",
          name: "crear automática retencion",
          component: () =>
            import("@/views/retenciones/formulario/formulario.vue"),
        },
        {
          path: "/retenciones-automaticas/update/:id",
          name: "actualizar automática retencion",
          component: () =>
            import("@/views/retenciones/formulario/formulario.vue"),
        },
        {
          path: "/configuraciones",
          name: "configuración variables",
          component: () =>
            import("@/views/configuraciones/configuraciones.vue"),
        },
        {
          path: "/puc",
          name: "plan único de cuentas",
          component: () =>
            import(
              "@/views/contabilidad/plan-unico-cuentas/plan-unico-cuentas.vue"
            ),
        },
        {
          path: "/impuestos",
          name: "impuestos",
          component: () =>
            import("@/views/contabilidad/impuestos/impuestos.vue"),
        },
        {
          path: "/impuestos/create",
          name: "crear impuestos",
          component: () =>
            import("@/views/contabilidad/impuestos/formulario/formulario.vue"),
        },
        {
          path: "/impuestos/update/:id",
          name: "actualizar impuestos",
          component: () =>
            import("@/views/contabilidad/impuestos/formulario/formulario.vue"),
        },
        {
          path: "/notas-contables",
          name: "notas contables",
          component: () =>
            import("@/views/contabilidad/notas-contables/notas-contables.vue"),
        },
        {
          path: "/notas-contables/create",
          name: "crear notas contables",
          component: () =>
            import(
              "@/views/contabilidad/notas-contables/formulario/formulario.vue"
            ),
        },
        {
          path: "/notas-contables/update/:id",
          name: "actualizar notas contables",
          component: () =>
            import(
              "@/views/contabilidad/notas-contables/formulario/formulario.vue"
            ),
        },
        {
          path: "/grupo-terceros",
          name: "grupos de terceros",
          component: () => import("@/views/grupo-terceros/grupo-terceros.vue"),
        },
        {
          path: "/grupo-terceros/create",
          name: "crear grupos de terceros",
          component: () =>
            import("@/views/grupo-terceros/formulario/formulario.vue"),
        },
        {
          path: "/grupo-terceros/update/:id",
          name: "actualizar grupos de terceros",
          component: () =>
            import("@/views/grupo-terceros/formulario/formulario.vue"),
        },
        {
          path: "/facturacion-masiva",
          name: "facturación masiva",
          component: () =>
            import("@/views/facturacion-masiva/facturacion-masiva.vue"),
        },
        {
          path: "/cuenta-bancaria-cliente",
          name: "cuenta bancaria compañia",
          component: () =>
            import(
              "@/views/cuenta-bancaria-cliente/cuenta-bancaria-cliente.vue"
            ),
        },
        {
          path: "/cuenta-bancaria-cliente/create",
          name: "crear cuenta bancaria compañia",
          component: () =>
            import("@/views/cuenta-bancaria-cliente/formulario/formulario.vue"),
        },
        {
          path: "/cuenta-bancaria-cliente/update/:id",
          name: "actualizar cuenta bancaria compañia",
          component: () =>
            import("@/views/cuenta-bancaria-cliente/formulario/formulario.vue"),
        },
        {
          path: "/control-periodos",
          name: "control de periodos",
          component: () =>
            import(
              "@/views/contabilidad/control-periodos/control-periodos.vue"
            ),
        },
        {
          path: "/recaudos",
          name: "recaudos",
          component: () => import("@/views/recaudos/recaudos.vue"),
        },
        {
          path: "/recaudos/create",
          name: "crear recaudo",
          component: () => import("@/views/recaudos/formulario/formulario.vue"),
        },
        {
          path: "/recaudos/update/:id",
          name: "actualizar recaudo",
          component: () => import("@/views/recaudos/formulario/formulario.vue"),
        },
        {
          path: "/egresos",
          name: "egresos",
          component: () => import("@/views/egresos/egresos.vue"),
        },
        {
          path: "/egresos/create",
          name: "crear egresos",
          component: () => import("@/views/egresos/formulario/formulario.vue"),
        },
        {
          path: "/egresos/update/:id",
          name: "actualizar egresos",
          component: () => import("@/views/egresos/formulario/formulario.vue"),
        },
        {
          path: "/empresa",
          name: "cliente",
          component: () => import("@/views/cliente/cliente.vue"),
        },
        {
          path: "/empresa/create",
          name: "crear cliente",
          component: () => import("@/views/cliente/formulario/formulario.vue"),
        },
        {
          path: "/empresa/update/:id",
          name: "actualizar cliente",
          component: () => import("@/views/cliente/formulario/formulario.vue"),
        },
        {
          path: "/cuenta-fecha-documento",
          name: "cuenta fecha documento",
          component: () =>
            import("@/views/cuenta-fecha-documento/cuenta-fecha-documento.vue"),
        },
        {
          path: "/asignacion",
          name: "asignación",
          component: () => import("@/views/asignacion/asignacion.vue"),
        },
        {
          path: "/asignacion/create",
          name: "crear asignación",
          component: () =>
            import("@/views/asignacion/formulario/formulario.vue"),
        },
        {
          path: "/asignacion/update/:id",
          name: "actualizar asignación",
          component: () =>
            import("@/views/asignacion/formulario/formulario.vue"),
        },
        {
          path: "/solicitud-facturacion",
          name: "solicitud facturación",
          component: () =>
            import("@/views/solicitud-facturacion/solicitud-facturacion.vue"),
        },
        {
          path: "/solicitud-facturacion/create",
          name: "crear solicitud facturación",
          component: () =>
            import("@/views/solicitud-facturacion/formulario/formulario.vue"),
        },
        {
          path: "/solicitud-facturacion/update/:id",
          name: "actualizar solicitud facturación",
          component: () =>
            import("@/views/solicitud-facturacion/formulario/formulario.vue"),
        },
        {
          path: "/importar-nota-contable",
          name: "Importar nota contable",
          component: () =>
            import(
              "@/views/contabilidad/importar-nota-contable/importar-nota-contable.vue"
            ),
        },
        {
          path: "/gastos-desde-nota-contable",
          name: "gastos desde nota contable",
          component: () =>
            import(
              "@/views/contabilidad/gastos-nota-contable/gastos-nota-contable.vue"
            ),
        },
        {
          path: "/gastos",
          name: "reporte de gastos",
          component: () => import("@/views/contabilidad/gastos/gastos.vue"),
        },
        {
          path: "/gastos/create",
          name: "crear reporte de gasto",
          component: () =>
            import("@/views/contabilidad/gastos/formulario/formulario.vue"),
        },
        {
          path: "/gastos/update/:id",
          name: "actualizar reporte de gasto",
          component: () =>
            import("@/views/contabilidad/gastos/formulario/formulario.vue"),
        },
        {
          path: "/cloudfleet-conceptos",
          name: "cloudfleet conceptos",
          component: () =>
            import("@/views/cloudfleet-conceptos/cloudfleet-conceptos.vue"),
        },
        {
          path: "/cloudfleet-conceptos/create",
          name: "crear cloudfleet conceptos",
          component: () =>
            import("@/views/cloudfleet-conceptos/formulario/formulario.vue"),
        },
        {
          path: "/cloudfleet-conceptos/update/:id",
          name: "actualizar cloudfleet conceptos",
          component: () =>
            import("@/views/cloudfleet-conceptos/formulario/formulario.vue"),
        },
        {
          path: "/informes",
          name: "informes",
          component: () => import("@/views/informes/informes.vue"),
        },
        {
          path: "/informe-transporte",
          name: "informe transporte",
          component: () => import("@/views/informes/informes-transporte.vue"),
        },
        {
          path: "/informes-contabilidad",
          name: "informes contabilidad",
          component: () => import("@/views/informes/informes-contabilidad.vue"),
        },
        {
          path: "/informes-documentos",
          name: "informes documentos",
          component: () => import("@/views/informes/informes-facturacion.vue"),
        },
        {
          path: "/distribucion-ingresos",
          name: "distribucion ingresos",
          component: () =>
            import(
              "@/views/contabilidad/distribucion-ingresos/distribucion-ingresos.vue"
            ),
        },
        {
          path: "/distribucion-ingresos/create",
          name: "crear distribucion ingreso",
          component: () =>
            import(
              "@/views/contabilidad/distribucion-ingresos/formulario/formulario.vue"
            ),
        },
        {
          path: "/distribucion-ingresos/update/:id",
          name: "actualizar distribucion ingreso",
          component: () =>
            import(
              "@/views/contabilidad/distribucion-ingresos/formulario/formulario.vue"
            ),
        },
        {
          path: "/roles",
          name: "roles",
          component: () => import("@/views/roles/roles.vue"),
        },
        {
          path: "/roles/create",
          name: "crear roles",
          component: () => import("@/views/roles/formulario/formulario.vue"),
        },
        {
          path: "/roles/update/:id",
          name: "actualizar roles",
          component: () => import("@/views/roles/formulario/formulario.vue"),
        },
        {
          path: "/usuarios",
          name: "usuarios",
          component: () => import("@/views/usuarios/usuarios.vue"),
        },
        {
          path: "/usuarios/create",
          name: "crear usuarios",
          component: () => import("@/views/usuarios/formulario/formulario.vue"),
        },
        {
          path: "/usuarios/update/:id",
          name: "actualizar usuarios",
          component: () => import("@/views/usuarios/formulario/formulario.vue"),
        },
        {
          path: "/tipos-documentos",
          name: "tipos documentos",
          component: () =>
            import("@/views/tipos-documentos/tipos-documentos.vue"),
        },
        {
          path: "/tipos-documentos/create",
          name: "crear tipo documento",
          component: () =>
            import("@/views/tipos-documentos/formulario/formulario.vue"),
        },
        {
          path: "/tipos-documentos/update/:id",
          name: "actualizar tipos documento",
          component: () =>
            import("@/views/tipos-documentos/formulario/formulario.vue"),
        },
        {
          path: "/bodegas",
          name: "bodegas",
          component: () => import("@/views/inventario/bodegas/bodegas.vue"),
        },
        {
          path: "/bodegas/create",
          name: "crear bodegas",
          component: () =>
            import("@/views/inventario/bodegas/formulario/formulario.vue"),
        },
        {
          path: "/bodegas/update/:id",
          name: "actualizar bodegas",
          component: () =>
            import("@/views/inventario/bodegas/formulario/formulario.vue"),
        },
        {
          path: "/productos-bodega",
          name: "productos bodega",
          component: () =>
            import("@/views/inventario/productos-bodega/productos-bodega.vue"),
        },
        {
          path: "/productos-bodega/create",
          name: "crear productos bodega",
          component: () =>
            import(
              "@/views/inventario/productos-bodega/formulario/formulario.vue"
            ),
        },
        {
          path: "/productos-bodega/update/:id",
          name: "actualizar productos bodega",
          component: () =>
            import(
              "@/views/inventario/productos-bodega/formulario/formulario.vue"
            ),
        },
        {
          path: "/recepcion-entrega",
          name: "recepcion y entrega",
          component: () =>
            import(
              "@/views/inventario/recepcion-entrega/recepcion-entrega.vue"
            ),
        },
        {
          path: "/recepcion-entrega/create",
          name: "crear recepcion y entrega",
          component: () =>
            import(
              "@/views/inventario/recepcion-entrega/formulario/formulario.vue"
            ),
        },
        {
          path: "/recepcion-entrega/update/:id",
          name: "actualizar recepcion y entrega",
          component: () =>
            import(
              "@/views/inventario/recepcion-entrega/formulario/formulario.vue"
            ),
        },
        {
          path: "/tablero-inventario",
          name: "tablero inventario",
          component: () =>
            import(
              "@/views/inventario/tablero-inventario/tablero-inventario.vue"
            ),
        },
        {
          path: "/tipo-empaque",
          name: "tipo empaque",
          component: () =>
            import("@/views/inventario/tipo-empaque/tipo-empaque.vue"),
        },
        {
          path: "/tipo-empaque/create",
          name: "crear tipo empaque",
          component: () =>
            import("@/views/inventario/tipo-empaque/formulario/formulario.vue"),
        },
        {
          path: "/tipo-empaque/update/:id",
          name: "actualizar tipo empaque",
          component: () =>
            import("@/views/inventario/tipo-empaque/formulario/formulario.vue"),
        },
        {
          path: "/informe-inventario",
          name: "informe inventario",
          component: () => import("@/views/inventario/informe/informe.vue"),
        },
        {
          path: "/informe-transacciones",
          name: "informes transacciones",
          component: () =>
            import("@/views/inventario/informe/informe-transacciones.vue"),
        },
        {
          path: "/informe-pago-proveedores",
          name: "informe pago proveedores",
          component: () =>
            import("@/views/egresos/informe/informe-pago-proveedores.vue"),
        },
        {
          path: "/tipo-producto",
          name: "tipo producto",
          component: () => import("@/views/tipo-producto/tipo-producto.vue"),
        },
        {
          path: "/tipo-producto/update/:id",
          name: "actualizar tipo producto",
          component: () =>
            import("@/views/tipo-producto/formulario/formulario.vue"),
        },
        {
          path: "/secuencias",
          name: "secuencias",
          component: () => import("@/views/secuencias/secuencias.vue"),
        },
        {
          path: "/secuencias/update/:id",
          name: "actualizar secuencias",
          component: () =>
            import("@/views/secuencias/formulario/formulario.vue"),
        },
        {
          path: "/rutas",
          name: "rutas",
          component: () => import("@/views/rutas/rutas.vue"),
        },
        {
          path: "/rutas/create",
          name: "crear rutas",
          component: () => import("@/views/rutas/formulario/formulario.vue"),
        },
        {
          path: "/rutas/update/:id",
          name: "actualizar rutas",
          component: () => import("@/views/rutas/formulario/formulario.vue"),
        },
        {
          path: "/api-key",
          name: "api key",
          component: () => import("@/views/api-key/api-key.vue"),
        },
        {
          path: "/api-key/create",
          name: "crear api key",
          component: () => import("@/views/api-key/formulario/formulario.vue"),
        },
        {
          path: "/api-key/update/:id",
          name: "administrar api key",
          component: () => import("@/views/api-key/formulario/formulario.vue"),
        },
      ],
    },
  ],
});

router.beforeEach((to, _, next) => {
  manejadorRutas(to, next);
});

export default router;
