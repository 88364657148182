import type { IBase, NivelRiesgoEnum } from "@/interfaces/base";

export interface IProducto extends Omit<IBase, "cliente_id"> {
  producto_id?: number;
  sede_id?: number | null;
  codigo: string;
  producto: string;
  tipo_producto_id: number | null;
  unidad_medida: number | null;
  impuesto_id: number | null;
  categoria_retencion_producto_id: number | null;
  es_porcentaje_descuento: boolean | null;
  es_descuento_transporte: boolean | null;
  tipo_descuento_manifiesto: string | null;
  tipo_descuento_vehiculo: string | null;
  porcentaje_descuento: number | null;
  valor_descuento: number | null;
  cuenta_fiscal_ingreso_id: number | null;
  cuenta_fiscal_costos_id: number | null;
  cuenta_niif_ingreso_id: number | null;
  cuenta_niif_costos_id: number | null;
  es_cargue_descargue: boolean | null;
  nivel_riesgo: NivelRiesgoEnum | null;
  valor_cxc: number;
  valor_cxp: number;
  valor_min: number;
  valor_max: number;
  tipo_carga?: TipoCargaEnum;
}

export enum TipoCargaEnum {
  NORMAL = "00",
  PELIGROSO = "CP",
  SERVICIO = "NULL",
}

export type ProductoTipo = keyof IProducto;

export interface ITipoProducto extends Omit<IBase, "sede_id" | "cliente_id"> {
  tipo_producto_id?: number;
  nombre: string;
  descripcion: string;
  producto_transporte: boolean;
  cuenta_fiscal_ingreso_id: number | null;
  cuenta_niif_ingreso_id: number | null;
  cuenta_fiscal_costos_id: number | null;
  cuenta_niif_costos_id: number | null;
}

export enum TipoProductoEmum {
  PRODUCTO_MINISTERIO = "PM",
  SERVICIOS_ADICIONALES = "SO",
  TODOS = "TO",
}

export interface IEmpaquePrimario {
  empaque_primario_id: string;
  codigo: string;
  descripcion: string;
  descripcion_completa: string;
  definicion: string;
  mercancia_peligrosa: string;
  nom_tipo_empaque: string;
  material_empaque: string;
  nom_material_empaque: string;
  tipo_operacion: string;
  peso_vacio_minimo: number;
  peso_vacio_maximo: number;
  tipo_empaque: number;
}
