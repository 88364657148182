import { http } from "@/utils/http";
import type { IParametros, IResultado } from "@/interfaces/requests";
import type { ICuenta } from "@/interfaces/cuenta";
import { IBanco } from "@/interfaces/banco";

export const obtenerTodosBancosServicio = async ({
  pagina,
  limite,
}: IParametros): Promise<IResultado> => {
  try {
    const response = await http.get(`/tercero/banco`, {
      params: { page: pagina, limit: limite },
    });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const obtenerBancoPoIdServicio = async (
  id: number
): Promise<IResultado> => {
  try {
    const response = await http.get(`/tercero/banco/${id}`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const crearBancoServicio = async (
  banco: IBanco
): Promise<IResultado> => {
  try {
    const response = await http.post(`/tercero/banco`, banco);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const actualizarBancoServicio = async ({
  banco_id,
  ...banco
}: IBanco): Promise<IResultado> => {
  try {
    const response = await http.post(`/tercero/banco/${banco_id}`, banco);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const eliminarBancoServicio = async (
  id: number
): Promise<IResultado> => {
  try {
    const response = await http.delete(`/tercero/banco/${id}`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const obtenerBancosServicio = async (): Promise<IResultado> => {
  try {
    const response = await http.get(`/tercero/banco`, {
      params: { page: 1, limit: 200 },
    });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const obtenerCuentaBusquedaServicio = async (
  busqueda: string
): Promise<IResultado> => {
  try {
    const response = await http.get(`/tercero/cuenta-bancaria`, {
      params: { busqueda },
    });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const obtenerCuentasServicio = async (
  tercero: number
): Promise<IResultado> => {
  try {
    const response = await http.get(
      `/tercero/cuenta-bancaria/tercero?tercero_id=${tercero}`
    );
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const crearCuentaBancariaServicio = async (
  cuenta: ICuenta
): Promise<IResultado> => {
  try {
    const response = await http.post(`/tercero/cuenta-bancaria`, cuenta);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const actualizarCuentaBancariaServicio = async ({
  cuenta_bancaria_id,
  ...cuenta
}: ICuenta): Promise<IResultado> => {
  try {
    const response = await http.patch(
      `/tercero/cuenta-bancaria/${cuenta_bancaria_id}`,
      cuenta
    );
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const eliminarCuentasServicio = async (
  cuenta: number
): Promise<IResultado> => {
  try {
    const response = await http.delete(`/tercero/cuenta-bancaria/${cuenta}`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
